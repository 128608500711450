// @import 'responsiveFonts';
// @import 'lineHeights';

.card-headings{
    // @extend .responsive-font-20;
    // @extend .line-height-30;
    font-size: 1rem !important;
    line-height: 1.5rem;
    font-weight: 600;
    color: #414141;
    height:3.2rem;
    align-items: center;
    display: flex;
}
.page-heading{
    font-size: 1.2rem !important;
    line-height: 1.8rem;
    font-weight: 600;
}
.font-24-heading{
    font-size: 1.2rem;
    line-height: 1.05rem;
}
.font-20-heading{
    font-size: 1rem;
    line-height: 1.5rem;
}
.font-16-heading{
    font-size: 0.8rem;
    line-height: 1.2rem;
}

.font-14-heading{
    font-size: 14px !important;
    line-height: 21px !important;
}
.font-12-heading{
    font-size: 12px !important;
    line-height: 18px !important;
}
.font-10-heading{
    font-size: 10px;
    line-height: 18px;
}
.widget-border{
  background: #FBFCFC; 
  border-radius: 6px; 
}

.disabled {
    color: rgb(153, 153, 153) !important;
    pointer-events: none !important;
}

.enabled {
    color: #2064B4;
    cursor: pointer;
}


