$Sushi:#7AA434;
$HunterGreen:#386641;
$LightGrey:#D1D1D1;
$SilverTree:#6EBA9A ;
$DarkGray:#A5A5A5;
$Charcoal:#414141;
$LinkWater:#CCCED0;
$Whisper:#E8E8E8;
$Azure:#F4F5F5;
$WhiteSmoke:#f6f6f6;
$Silver:#C5C5C5;
$White:#FFFFFF;
$Primary:#2064B4;
$Red:#CC0000;
$CriticalRed:#D43136;
$Green:#478B1A;
$SuccessGreen:#D7E6CF;
$Orange:#F27C27;
$LightPink:#F4D3D4;
$Nobel:#999999;
$Azure2:#FBFCFC;


.color-link-water{
    color: $LinkWater;
 }
.border-link-water{
    border: 1px solid $LinkWater;
 }
.bg-link-water{
    background-color: $LinkWater;
 }

.color-whisper{
    color: $Whisper;
 }
.border-whisper{
    border: 1px solid $Whisper;
 }
.bg-whisper{
    background-color: $Whisper;
 }

.color-azure2{
    color: $Azure2;
 }
.border-azure2{
    border: 1px solid $Azure2;
 }
.bg-azure2{
    background-color: $Azure2;
 }


.color-nobel{
    color: $Nobel;
 }
.border-nobel{
    border: 1px solid $Nobel;
 }
.bg-nobel{
    background-color: $Nobel;
 }

.color-light-pink{
    color: $LightPink;
 }
.border-light-pink{
    border: 1px solid $LightPink;
 }
.bg-light-pink{
    background-color: $LightPink;
 }

.color-orange{
    color: $Orange;
 }
.border-orange{
    border: 1px solid $Orange;
 }
.bg-orange{
    background-color: $Orange;
 }

.color-success-green{
    color: $SuccessGreen;
 }
.border-success-green{
    border: 1px solid $SuccessGreen;
 }
.bg-success-green{
    background-color: $SuccessGreen;
 }


.color-green{
    color: $Green;
 }
.border-green{
    border: 1px solid $Green;
 }
.bg-green{
    background-color: $Green;
 }


.color-critical-red{
    color: $CriticalRed;
 }
.border-critical-red{
    border: 1px solid $CriticalRed;
 }
.bg-critical-red{
    background-color: $CriticalRed;
 }

.color-red{
    color: $Red;
 }
.border-red{
    border: 1px solid $Red;
 }
.bg-red{
    background-color: $Red;
 }

.color-charcoal{
    color: $Charcoal;
 }
.border-charcoal{
    border: 1px solid $Charcoal;
 }
.bg-charcoal{
    background-color: $Charcoal;
 }


.color-primary{
    color: $Primary;
 }
.border-primary{
    border: 1px solid $Primary;
 }
.bg-primary{
    background-color: $Primary;
 }

.color-azure{
    color: $Azure;
 }
.border-azure{
    border: 1px solid $Azure;
 }
.bg-azure{
    background-color: $Azure;
 }

.color-linkWater{
    color: $LinkWater;
 }
.border-linkWater{
    border: 1px solid $LinkWater;
 }
.bg-linkWater{
    background-color: $LinkWater;
 }