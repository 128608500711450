@import "colors";

$font-10 :10px; //10px;
$font-12 :12px; //12px;
$font-14 :14px;  //14px;
$font-16 :1rem; //16px;
$font-20 :1.25rem; //20px;
$font-24 :1.5rem; //24px;
$font-30 :2rem; //30px;
@mixin  fontStyle($size,$color) {
   font-size:$size; 
   color: $color;

//    letter-spacing: 1.2px;
}
.font-weight-600{
  font-weight: 600 !important;
}
.font-weight-400{
    font-weight: 400 !important;
  }
.font-weight-bold{
    font-weight: bold !important;
}
.italic {
    font-style: italic;
}

.font-xm-dark{
   @include fontStyle($font-10,$Charcoal)
}
.font-sm-dark{
    @include fontStyle($font-12,$Charcoal)
}
.font-m-dark{
    @include fontStyle($font-14,$Charcoal)
}
.font-l-dark{
    @include fontStyle($font-16,$Charcoal)
}
.font-xl-dark{
    @include fontStyle($font-20,$Charcoal)
}
.font-xxl-dark{
    @include fontStyle($font-24,$Charcoal)
}
.font-xm-light{
    @include fontStyle($font-10,$DarkGray)
 }
 .font-sm-light{
     @include fontStyle($font-12,$DarkGray)
 }
 .font-m-light{
     @include fontStyle($font-14,$DarkGray)
 }
 .font-l-light{
     @include fontStyle($font-16,$DarkGray)
 }
 .font-xl-light{
     @include fontStyle($font-20,$DarkGray)
 }
 .font-xxl-light{
    @include fontStyle($font-24,$DarkGray)
}
.font-20{
    font-size: 20px !important;
}
.font-24{
    font-size: 24px !important;
}
.font-10{
    font-size: 10px !important;
}
.font-12{
    font-size: 12px !important;
}
.font-14{
    font-size: 14px !important;
}
.font-16{
    font-size: 16px !important;
}
.font-18{
    font-size: 18px !important;
}
// .font-line-height-36{
//     line-height: 36px;
// }
// .font-line-height-31{
//     line-height: 31px;
// }
// .font-line-height-21{
//     line-height: 21px;
// }
// .font-line-height-18{
//     line-height: 18px;
// }

// .font-letter-spacing-2-88{
//     letter-spacing:2.88px;
// }
// .font-letter-spacing-1-68{
//     letter-spacing:1.68px;
// }
// .font-letter-spacing-2-4{
//     letter-spacing:2.4px;
// }
// .font-letter-spacing-1-2{
//     letter-spacing:1.2px;
// }

// .font-letter-spacing-1-44{
//     letter-spacing:1.44px;
// }
.font-color{
    color: #414141 !important;
}
.font-color-hyperlink{
    color: #2064b4;
}
.font-m-white{
    @include fontStyle($font-14,$White)
}
.font-xm-white{
   @include fontStyle($font-10,$White)
}
.font-xl-white{
    @include fontStyle($font-20,$White)
 }
.font-sm-primary{
    @include fontStyle($font-12,$Primary)
}
.font-m-primary{
    @include fontStyle($font-14,$Primary)
}

.font-xxxl-primary{
    @include fontStyle($font-30,$Primary)
}
.font-xxxl-dark{
    @include fontStyle($font-30,$Charcoal)
}

.all-upper-case{
   text-transform: uppercase;
 }
.all-lower-case{
    text-transform: lowercase;
}
.capital-first-letter{
    text-transform: capitalize;    
 }
