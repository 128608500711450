$line-heights: (); // Initialize an empty map

@for $i from 1 through 100 {
  $line-heights: map-merge($line-heights, ($i: #{$i}px)); // Add line height to the map
}

@each $size, $value in $line-heights {
  .line-height-#{$size} {
    line-height: $value;
  }
}

// @media only screen and (max-width: 768px) {
//   @each $size, $value in $font-sizes {
//     .responsive-font-#{$size} {
//       font-size: calc(#{$value} - 2px);
//     }
//   }
// }

// @media only screen and (min-width: 769px) and (max-width: 900px) {
//   @each $size, $value in $font-sizes {
//     .responsive-font-#{$size} {
//       font-size: $value;
//     }
//   }
// }

// @media only screen and (min-width: 901px) and (max-width: 1440px) {
//   @each $size, $value in $font-sizes {
//     .responsive-font-#{$size} {
//       font-size: calc(#{$value} - 2px);
//     }
//   }
// }

// @media only screen and (min-width: 1441px) {
//   @each $size, $value in $font-sizes {
//     .responsive-font-#{$size} {
//       font-size: $value;
//     }
//   }
// }
