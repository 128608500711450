.border-bottom {
  border-bottom: 1px solid #D1D1D1;
}

.border-top {
  border-top: 1px solid #D1D1D1;
}

.primary-btn {
  border: 1px solid #2064B4;
  color: #2064B4;
  border-radius: 2px;
  cursor: pointer;
  padding: 4px 12px;
}

.graph-border {
  border-radius: 6px;
  border: 1px solid #CCCED0;
  background: #FBFCFC;
}

.h-100 {
  height: 100%
}


.percent-bar {
  display: grid;
  grid-template-columns: 7fr 1fr;
  gap: 8px
}

.parallel-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.parallel-columns-for-single {
  display: grid;
  grid-template-columns: repeat(1fr);
  gap: 10px;
  max-width: 700px;
}

.parallel-columns>* {
  border-right: 1px solid #ccc;
  padding-right: 10px;
}

.parallel-columns>*:last-child {
  border-right: none;
}

.bucket-metrics-max-height {
  max-height: 300px;
  overflow-y: auto;
}

.border-right {
  border-right: 1px solid #D1D1D1;
}

.circular-legends {
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 0.6rem
}

.pool-aggregate-css {
  border: 1px solid #D1D1D1;
  border-radius: 6px;
}

.status-icon {
  position: relative;
  right: 6px;
  bottom: 3px;
}

.host-group-icon {
  margin-bottom: 89px;
  width: 16px;
}

.align-right {
  margin-left: auto;
  margin-right: 0px;
}

@media screen and (min-width: 1279px) and (max-width: 1423px) {
  .host-group-icon {
    margin-bottom: 91px;
    width: 16px;
  }

}