$Primary: #2064B4; //hyperlink,buttons
$Red: #D43136;
$TextColor: #414141;
$Whisper: #E8E8E8;
$Azure: #FBFCFC;
$MinusHeight: 120px;

._hj_feedback_container ._hj--NfhW__ExpandedWidget__innerContainer {
  width: auto !important;
}

html {
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 900px) {
  html {
    font-size: 16px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1334px) {
  html {
    font-size: 16px;

    .position-temp-cls {
      position: absolute;
      right: 21%;
    }
  }

}

@media only screen and (min-width: 1336px) and (max-width: 1480px) {
  html {
    font-size: 18px;

    .position-temp-cls {
      position: absolute;
      right: 21%;
    }
  }
}

@media only screen and (min-width: 1481px) {
  html {
    font-size: 20px;
  }
}



html,
body,
#root {
  height: 100%;
}

.aperture-main-content-height {
  height: calc(100% - 51px);
}

body,
input {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::placeholder {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif !important;
}
.highlight-background {
  background: #1775E01A;
}

.custom-tooltip-title {
  font-weight: 600;
  padding: 16px 16px 10px 16px;
  border-bottom: 1px solid $Whisper;
}

.custom-tooltip-subtitle {
  padding: 10px 16px 10px 16px;
  font-weight: 600;
  font-size: 14px;

}

.custom-tooltip-body,
.custom-tooltip-content {
  padding: 10px 16px 8px 16px;
  display: flex;
  justify-content: space-between;
}

.custom-tooltip-content {
  padding: 0px 16px 8px 16px
}

.custom-tooltip-body,
.custom-tooltip-label {
  font-weight: 600;
}

.custom-tooltip-body,
.custom-tooltip-content .custom-tooltip-value {
  font-weight: 400;
}

.body-small-content,
.MuiFormControlLabel-label,
#standard-search {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 21px;
  text-overflow: ellipsis;
  color: $TextColor !important;
}

@media (max-width: 600px) {
  #standard-search {
    font-size: 16px !important;
  }
}

@media (max-width: 600px) {
  #standard-search {
    font-size: 16px !important;
  }
  ._hj-widget-container{
    display: none;
  }
}

.body-small-content-asset-report {
  text-overflow: ellipsis;
}

.lh-24 {
  line-height: 24px;
}

.links-to-open-separate-window {
  font-weight: 600;
  font-size: 10px;
  color: $Primary;
}

.chatbot-css {
  z-index: 2247483640;
}

.button-css {
  height: 32px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
}

.button-label-color {
  color: $Azure;
}

.h-65 {
  min-height: 65px;
}

.h-45 {
  min-height: 45px !important;
}

.primary-button-css {
  color: $Azure !important;
  background-color: $Primary !important;
}

.button-padding-withoutIcon {
  padding: 8px 16px 8px 16px !important;
}

.center-align-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.capitalize-first-letter {
  text-transform: capitalize;
}

.to-uppercase {
  text-transform: uppercase;
}

.h-60 {
  min-height: 60px;
}

a {
  text-decoration: none;
  color: $Primary;
}

a:focus,
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: $Primary;
}

:focus {
  outline: none;
}

.row_gap_5 {
  row-gap: 5px;
}

.bold {
  font-weight: 600 !important;
}

.pointer {
  cursor: pointer;
}

.permissions_logo {
  float: left;
}

.no_margin {
  margin: 0;
}

.no_padding.no_padding {
  padding: 0;
}

.padding {
  padding: 15px;
}

.text_center {
  text-align: center !important;
}

.mirror {
  transform: rotateY(180deg);
}

.microcode-icon {
  position: relative;
  top: 3px;
}

.margin-top-15 {
  margin-top: 15px;
}

.border-none {
  border: none !important;
}

.table-width {
  width: 21%;
  word-break: break-all !important;
}

.width-vspGraph-dropdown {
  width: 32%;
}

.icon-cls {
  color: black;
  height: 16px !important;
  width: 16px !important;
}





.permission-table-search {
  width: 27%;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.MuiFormControl-root {
  width: 100%;
}

.w-full {
  width: 100%;
}

.w-noresultmsg-fs {
  width: 66%;
}

.margin-right-10-imp {
  margin-right: 10px !important;
}

.underline {
  text-decoration: underline;
}

.margin-right-5-percent {
  margin-right: 5%;
}

.display-inline-block {
  display: inline-block;
}

.float-right {
  float: right;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.padding-hcp-graph {
  padding: 0px 0px 0px 13px !important;
}

.link-color {
  color: $Primary !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.hide {
  visibility: hidden;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 107px;
}

.units-font {
  font-size: 10px;
  color: #414141;
  font-weight: 400;
  text-align: right;
  line-height: 10px;
}

.info-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #6C6B6B;
}

.pagination {
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 0 15px;
  border-collapse: separate !important;
  background: white;
}

#pagination>div>td {
  padding: 0px !important
}

.pagination-range {
  border: 1px solid #6C6B6B;
  padding: 4px;
  margin-right: 4px;
  border-radius: 2px;
}

.max-content-width {
  width: max-content;
}

.defaultCursor {
  cursor: default !important;
}


.ml {
  margin-left: 24px !important;
}

/*
Menu bar top */
/* Add a black background color to the top navigation */
.header_subcontent_width-left {
  width: 30%;
}

.header_subcontent_width-right {
  width: 24%;
}

.dashboard_header_selector-container {
  max-width: 24% !important;
  flex-basis: 24% !important;
}

.dashboard_header_selector {
  flex-grow: 0;
  max-width: 18.813rem !important;
  flex-basis: 18.813rem !important
}

.topnav {
  background-color: #FBFCFC;
  color: rgba(0, 0, 0, 0.87);
  overflow: hidden;
  height: 64px;
  padding: 16px;
  box-shadow: 0px 0px 12px rgba(65, 65, 65, 0.12) !important;
  z-index: 16;
  position: relative;

}

.menu-options {
  color: $TextColor;
  font-size: 14px !important;
  padding-left: 20px;
  height: 43px;
  align-items: center;
  display: flex;
}

.menu-options.active {
  font-weight: 600;
  border-left: 4px solid $TextColor;
}

.toggle-btn {
  color: black;
  border: none;
  width: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

li {
  padding: 10px;
}

.submenu {
  display: none;
}

.submenu.open {
  display: block;
}

.active {
  background-color: #1775e014;
}

.sidebar-link-container .menu-options {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 0px 4px 16px !important;
}

.sidebar-link-container .menu-option-icon {
  padding: 4px;
  width: 32px;
  height: 32px;
}

.sidebar-link-container .menu-options.active {
  font-weight: 600;
  padding-left: 10px !important;
  color: $Red;
  border-bottom: none;
  border-left: 4px solid $Red;
  background: #F4F5F5 0% 0% no-repeat padding-box;
  width: 100%;
}

.user-name-avtar-width {
  height: 32px;
  width: 32px;
}

.user-name-avatar {
  background: $TextColor;
  color: #ffffff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 12px !important;
  line-height: 21px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.min-width {
  min-width: 0px !important;
}

.graph-flex-wrap {
  flex-wrap: wrap-reverse;
  gap: 8px;
}

.logout-text {
  padding-right: 0px !important;
}

.topnav-middle {
  margin-left: 38.5%;
}

.topnav-middle-partner {
  margin-left: 47.5%;
}

.topnav span {
  float: left;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */

.topnav .menu-options:hover {
  cursor: pointer;
}

/* Right-aligned section inside the top navigation */
.topnav-right {
  float: right;
}

.for-medium-device {
  display: none !important;
}

.for-large-device {
  display: block !important;
}

.progress {
  position: relative;
  height: 16px;
  display: block;
  overflow: hidden;
  background-color: lightgrey;
  padding: 6px 0px 6px 0px;
}

.progress .determinate {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #e68c18;
  -webkit-transition: width .3s linear;
  transition: width .3s linear;
}

.progress .indeterminate {
  background-color: #26a69a;
}

.progress .indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress .indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

.progress .determinate.primary {
  background-color: #146BD2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 1.3;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
}

h1 {
  font-size: 3.56rem;
  line-height: 110%;
  margin: 2.8rem 0 1.68rem 0;
}

h2 {
  font-size: 2.92rem;
  line-height: 110%;
  margin: 2.3733333333rem 0 1.424rem 0;
}

h3 {
  font-size: 2.28rem;
  line-height: 110%;
  margin: 1.9466666667rem 0 1.168rem 0;
}

h4 {
  font-size: 1.64rem;
  line-height: 110%;
  margin: 1.52rem 0 0.912rem 0;
}

h5 {
  font-size: 1.15rem;
  line-height: 110%;
  margin: 1.0933333333rem 0 0.656rem 0;
}

h6 {
  font-size: 1rem;
  line-height: 110%;
  margin: 0.7666666667rem 0 0.46rem 0;
}

strong {
  font-weight: 500;
}

small {
  font-size: 75%;
}

.light {
  font-weight: 300;
}

.thin {
  font-weight: 200;
}

.MuiSelect-root .MuiSelect-select:focus {
  background-color: unset;
}

.MuiButtonBase-root.MuiButton-contained {
  box-shadow: none;
}

ul>li.MuiMenuItem-root {
  height: unset;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.loginForm-inputUser {
  display: none;
}

.loginForm-inputPassword {
  display: none;
}

.search-input {
  grid-template-columns: "auto"
}

.MuiButtonBase-root.MuiButton-outlined {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 5px 16px;
  background-color: white;
}

.MuiButtonBase-root.MuiButton-containedPrimary {
  border-radius: 2px !important;
}

.MuiButtonBase-root.MuiButton-containedPrimary:hover {
  box-shadow: none;
}

.MuiButtonBase-root.MuiButton-containedError {
  color: #fff;
  background-color: #ff5252;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 5px 16px;
}

.MuiAppBar-root.MuiAppBar-root {
  min-width: unset;
}

#permission_slider_id,
#add_asset_slider_id,
#olympus_slider_id,
#multiShare_permission_slider_id {
  position: relative;
  margin-top: 64px;
  left: -28rem;
  width: 26em;
  height: calc(100% - 50px);
  background: white;
  transition: all 300ms linear;
  z-index: 15;
  overflow: auto;
}

#permission_slider_id.active,
#add_asset_slider_id.active,
#olympus_slider_id.active,
#multiShare_permission_slider_id.active {
  left: 0rem;
}

#permission_slider_id.inactive,
#add_asset_slider_id.inactive,
#olympus_slider_id.inactive,
#multiShare_permission_slider_id.inactive {
  transition: 1s;
  left: -28rem;
}

.other-actions-div {
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  flex: auto;
  white-space: nowrap;
  margin-right: 10px;
  font-weight: 600;
}

.other-actions-main-div {
  display: flex;
  justify-content: flex-start;
}

.OtherAction-MainDiv {
  display: flex;
  justify-content: flex-start;
}

.other-action-icon-sec {
  font-size: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin-left: 10px;
}

.Other-ActionIcon-Sec {
  font-size: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin-left: 10px;

}

.other-actions-for-partner .other-action-icon-sec {
  width: calc(100% / 2);
}

.other-actions-icon {
  width: 1em;
  height: 1em;
  font-size: 24px;
  cursor: pointer;
}

.Other-ActionsIcon {
  width: 1em;
  height: 1em;
  font-size: 24px;
  cursor: pointer;
}

.other-action-icon-sec svg,
.other-action-icon-sec img {
  width: 32px;
  height: 32px;
}

.Other-ActionIcon-Sec svg,
.Other-ActionIcon-Sec img {
  width: 32px;
  height: 32px;

}

.hover-cls {
  width: 32px;
  height: 32px;
  border-radius: 32px;

  &:hover {
    background-color: #e8e8e8;
  }
}

.notification-hover-cls {
  width: 36px;
  height: 36px;
  border-radius: 36px;

  &:hover {
    background-color: #e8e8e8;
  }
}

.notification-hover-cls {
  width: 36px;
  height: 36px;
  border-radius: 36px;

  &:hover {
    background-color: #e8e8e8;
  }
}

.asset-report-icon-pt {
  padding-top: 4px;
}

.only-for-tab-device {
  display: none;
}

.location-grid {
  grid-column-start: 2;
  grid-column-end: 4;
}

.hcp-location-grid {
  grid-column-start: 1;
  grid-column-end: 3;
}

.reduce-location .location-grid {
  grid-column-start: 2;
  grid-column-end: 3;
}

.other-actions-text {
  margin-left: 2%;
  white-space: nowrap;
}

.Other-Actions-Text {
  margin-left: 2%;
  white-space: nowrap;
}

.display-grid-with-auto-columns-width {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 25px 32px;
}

.template-column-1-3 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.pop-over-delete {
  padding: 16px;
  max-width: 452px;
}

.pop-over-hnas {
  padding: 20px;
}

.error-status {
  background-color: #F4D3D4 !important;
}

.na-status {
  display: flex;
  align-items: center;
  min-height: 50px;
  font-size: 12px;
  padding: 0px;
  margin-bottom: 2%;
  transition: all 1s;
  max-width: 600px;
  position: fixed;
  right: 46px;
  width: max-content;
  min-width: 303px;
}

.permission-na-status {
  padding: 12px;
  position: fixed;
  margin-top: 0;
  margin-bottom: 0;
  width: max-content;
  top: 64px;
  z-index: 15;
  right: 36px;
}

.success-status {
  background-color: #D7E6CF;
}

.inventory-na-status {
  z-index: 5;
  padding: 1%;
  position: absolute;
  right: 26px;
}

.select-all-na-status {
  z-index: 5;
  font-size: 16px;
  padding: 1%;
  margin-top: 0;
  height: fit-content;
  margin-bottom: 1%;
}

.user-email {
  background-color: #BDCDEF;
  display: inline-flex;
}

.new-users {
  font-size: 10px;
  display: inline-block;
  line-height: 12px;
  padding: 6px 0px 6px 6px;
  margin: auto 0;
}


.new-users-block .clear-icon {
  width: 24px;
  height: 24px;
}

.existing-users {
  background-color: #cfe0f5;
  font-size: 13px;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  padding-left: 2%;
  padding-right: 2%;
  margin-left: 2%;
  margin-top: 2%;
  display: inline-block;
  transition: all 2s;
}

.existing-user-list {
  // margin-top: 2%;
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  gap: 6px;
  max-height: calc(100vh - 432px);
  overflow: auto;
  width: 100%;
}

#permission_slider_id::-webkit-scrollbar,
#add_asset_slider_id::-webkit-scrollbar,
#olympus_slider_id::-webkit-scrollbar,
#multiShare_permission_slider_id::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

.existing-user-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

#permission_slider_id::-webkit-scrollbar-thumb,
.existing-user-list::-webkit-scrollbar-thumb,
#add_asset_slider_id::-webkit-scrollbar-thumb,
#olympus_slider_id::-webkit-scrollbar-thumb,
#multiShare_permission_slider_id::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.existing-users-text {
  font-size: 13px;
  color: #146BD2;
  display: flex;
  margin-bottom: 2%;
}

.grey-out {
  position: absolute;
  left: 0;
  z-index: 11;
  display: block;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.custom-grey-out {
  position: absolute;
  left: 0;
  z-index: 11;
  display: block;
  opacity: 0.5;
  width: 100%;
  height: calc(100vh - 64px);
  background: rgba(0, 0, 0, 0.5);
}

.for-small-device {
  display: none !important;
}

.aperture-logo {
  width: 74px;
  height: 24px;
  position: relative;
  top: 3px;
}

.curcular-progress-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CUSTOMER .tbl-css-for-large-device tr>th:nth-child(2) span {
  width: 100px;
  justify-content: flex-end;
  left: -64px;
}

.tbl-css-for-large-device tr>th.customer-org-name span {
  width: 120px !important;
  justify-content: flex-start;
}


.tbl-css-for-large-device tr>th:nth-child(2) span {
  width: 100px;
  justify-content: flex-end;
}

.tbl-css-for-large-device tr>th:first-child,
.tbl-css-for-large-device tr>td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
  text-align: right;
}

.tbl-css-for-large-device tr>td:nth-child(2),
.tbl-css-for-large-device tr>th:nth-child(2) {
  position: sticky;
  left: 56px;
  background-color: white;
  z-index: 1;
}

.tbl-css-for-large-device tr>td:nth-child(3),
.tbl-css-for-large-device tr>th:nth-child(3) {
  position: sticky;
  left: 202px;
  background-color: white;
  z-index: 1;
  border-right: solid 1px #e8e8e8;
  text-align: left;
}

.tbl-css-for-large-device tr>td:last-child,
.tbl-css-for-large-device tr>th:last-child {
  position: sticky;
  right: 0px;
  background-color: white;
  z-index: 1;
  border-left: solid 1px #e8e8e8;
}

.border-left {
  border-left: solid 1px #e8e8e8;
}

.border-right {
  border-right: solid 1px #e8e8e8;
}

.padding-0 {
  padding-left: 0px !important;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
  position: relative;
  top: 2px;

}

.MuiPaper-elevation8 {
  box-shadow: 0px 0px 12px rgba(65, 65, 65, 0.15) !important;
  background-origin: 1px border #DEDEDE;
}

.acknowledge-form-label {
  align-items: flex-start !important;
  font-size: 14px;
  line-height: 21px;
  color: $TextColor;
  font-weight: 400;
}

.acknowledgement-checkbox>svg {
  width: 20px;
  height: 20px;
  position: relative;
  top: 2px;
}

.acknowledge-form-label>span {
  padding-top: 0px;
}

.MuiTablePagination-toolbar>div.flex {
  margin-right: 15%;
}

.no-data-available-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  min-height: 300px;
}

.eula-title {
  font-weight: 800;
  font-size: 15px;
  text-align: left;
}

.eula-body {
  display: block !important;
  padding: 8px 16px !important;
}

.eula-content {
  width: 45% !important;
  background-color: #DCDCDC !important;
}

.align-text-right {
  text-align: right;
}

.eula-header {
  padding: 1% 16px;
  justify-content: flex-end !important;
  align-items: center;
}

.eula-header h5 {
  font-weight: 800;
}

.eula-text {
  border: 1px solid black;
  padding: 10px;
  height: calc(100vh - 260px);
  overflow-y: scroll;
  background-color: white;
}

.eula-text body,
.bg-white {
  background-color: white;
}

.eula-buttons {
  color: black !important;
  background-color: transparent !important;
  border: none !important;
  font-weight: 700 !important;
}

.eula-buttons.selected {
  background-color: black !important;
  color: white !important;
}

.eula-buttons:hover {
  background-color: #cccccc !important;
}

.eula-buttons:disabled {
  background-color: transparent !important;
  color: #928c8c !important;
}

/* css after removal of hv_lib */
.dashboard_header_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}

.storage-type-container {
  display: flex;
  margin: auto 0;
}

.dashboard_header_name {
  max-width: 100%;
  flex-basis: 100%;
  flex-grow: 0;
}

// .dashboard_header_selector {
//   flex-grow: 0;
//   max-width: 100%;
// }

.asset-actions-button-container {
  flex-grow: 0;
  padding: 15px;
  margin: 0;
  box-sizing: border-box;
}

.typography-xsTitle {
  overflow-wrap: break-word;
  margin: 0;
}

.disabled-label {
  color: #999999 !important;
}

.active-label {
  color: $Primary !important;
}

.left-align {
  display: flex;
  justify-content: left;
}

.margin-center {
  display: flex;
  margin: auto 0;
}

.model-image {
  width: 12%
}

.typo-border {
  border-bottom: 1px solid #dddddd;
}

.analytics-border {
  padding: 0px 16px 0px 16px;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  margin: 0 auto;
}

.storage-icon {
  width: 32px;
  height: 30px;
  padding: 8px;
}

.selected-storage-icon {
  margin-right: 6px;
}

.typography-xmTitle {
  text-align: left;
  padding: 12px 16px 0px 16px;
}

tr {
  border-bottom: 1px solid #dddddd;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.highlightText {
  letter-spacing: 0.02em;
  margin: 0;
  margin-top: 8px;
}

.inventory-sub-header {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
  margin-top: 6px;
}

.table-container {
  border: 1.5px solid #d1d1d1;
  margin-top: 20px;
  border-radius: 4px 4px 0px 0px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(65, 65, 65, 0.0588235294);
}

.table-container-inventory-partner {
  border: 1.5px solid #d1d1d1;
  // margin-top: 20px;
  border-radius: 4px 4px 0px 0px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(65, 65, 65, 0.0588235294);
}

.inventory-subheader-search {
  max-width: 100%;
}

.inventory-subheader-sort-selector,
.inventory-subheader-actions {
  flex-grow: 0;
}

.inventory-sort-select {
  width: 100%;
}

.inventory-subheader-view-selector {
  flex-grow: 0;
}

.inventory-subheader-right {
  width: 55%;
  padding-right: 12px;
}

.inventory-subheader-right-tableview {
  display: flex;
  justify-content: flex-end;
  width: 50%
}

.search-input-wrapper {
  display: grid;
  grid-template-columns: auto 40px;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid #6C6B6B;
  border-radius: 2px;
  background: #fff;
}

search-input>div {
  width: 100% !important
}

.inventory-asset-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: -15px;
}

.footer-container {
  width: 100%;
  height: 40px;
  position: fixed;
  background: $Azure;
  color: $TextColor;
  border-top: 1px solid #bfbfbf;
  max-width: 1920px;
}

.footer-content-left {
  float: left;
  margin-left: 20px;
}

.footer-content-right {
  float: right;
  margin-right: 22px;
}

.width-height-point-seven {
  width: 0.7em !important;
  height: 0.7em !important;
}

.pagination-rows-count {
  position: relative;
  right: 52px;
}

.pagination-rows-count-100 {
  position: relative;
  right: 56px;
}

.pagination-count-section {
  cursor: default;
  position: relative;
  right: 24px;
}

.white-space-unset {
  white-space: unset !important;
}

.white-space-break {
  white-space: break-spaces !important;
}

.tooltipCustom {
  position: relative;
}

.tooltipCustom .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 700;
  bottom: 150%;
  left: -40px;
  margin-left: -60px;
  white-space: pre-wrap;
}

.tooltipCustom .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltipCustom:hover .tooltiptext {
  visibility: visible;
}

.react-select-tooltip-container {
  position: relative;
  width: 100%;
}

.react-select-tooltip-container .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 700;
  bottom: 150%;
  left: -40px;
  white-space: pre-wrap;
}

.react-select-tooltip-container .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.react-select-tooltip-container:hover .tooltiptext {
  visibility: visible;
}

.per-cls {
  color: $TextColor;
}

.credentials-layout-cls {
  display: grid;
  grid-template-columns: 20% 1fr 1fr;
  gap: 20px;
}

.ms-span-1 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}

.ms-span-2 {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}

.ms-span-3 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}

.ms-span-4 {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
}

.ms-span-1-2 {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
}

.ms-span-2-2 {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
}

.ms-span-5 {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}

.reduce-location .ms-span-5 {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
}

.ms-span-6 {
  -ms-grid-row: 1;
  -ms-grid-column: 6;
}

.ms-span-7 {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
}

.ms-span-8 {
  -ms-grid-row: 1;
  -ms-grid-column: 8;
}

.ms-span-1-3 {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
}

.ms-span-2-3 {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
}

.ms-span-1-4 {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
}

.ms-span-2-4 {
  -ms-grid-row: 4;
  -ms-grid-column: 2;
}

.ms-span-1-5 {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
}

.ms-span-2-5 {
  -ms-grid-row: 5;
  -ms-grid-column: 2;
}

.ms-div-grid {
  display: -ms-flexbox;
}

.ms-div-grid>svg {
  width: 32px;
}

.logout-popover {
  padding: 5%;
  border: 2px solid $Primary;
}

.logout-popover-title {
  color: $Primary !important;
}

.logout-popover-content {
  color: $TextColor !important;
}

.hsae-popover-text {
  width: 400px;
}

.hnas-performance-date {
  margin-left: auto;
}

.grid-button {
  width: 49%
}

.logout-popover-button-border {
  border: 1px solid $Primary !important;
}

.asset-detail-img-width {
  min-width: 100px;
  width: 100%;
}

.asset-detail-img-width.img-no-inaaccess {
  width: 50px;
}

.nav-flex {
  display: flex;
  align-items: center;
}

.aperture-logo-span {
  border-left: 1px solid black;
}

.aperture-logo-1 {
  width: 22px;
  margin-top: 5px;
}

.asset-report-icon-span {
  margin-left: 10px;
  padding-top: 6px;
}

.no_left_right_padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-padding-top {
  padding-top: 0px !important;
}

.inventory_header {
  padding-left: 0px !important;
  padding-right: 15px !important;
}

.main-content-flex {
  display: flex;
  flex-direction: column;
}

.permissions-panel-div {
  position: fixed;
  height: calc(100%);
  z-index: 50;
  overflow: hidden;
  width: 28rem;
}

.visibility-none {
  visibility: hidden;
}

.asset-table-style {
  overflow: auto;
  padding: 0px 15px;
}

.divider-icon {
  padding: 6px 0px 6px 0px;
}

.filter-container {
  display: flex;
  align-items: center;
  margin-right: 14px;
}

.filter-icon {
  width: 32px;
  height: 32px;
}


.grid-view-container,
.table-view-container {
  border: 1px solid #d1d1d1;
  background: #F4F5F5;
  display: inline-flex;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.grid-view-icon,
.table-view-icon {
  padding: 6px;
}

.active-icon {
  border: 1px solid $TextColor;
  background: #fff;
}

.inventory-icons-block {
  justify-content: center;
}

.group-btns {
  display: flex;
  gap: 16px
}

.disabled {
  pointer-events: none;
  opacity: .5;
}

.auto-margin {
  margin: auto 0;
}

.equi-margin {
  margin-left: 6px;
  margin-right: 6px;
}

.w-26 {
  width: 26px;
}

.add-asset-permission-sec {
  padding: 24px 24px 24px 24px;
  height: calc(100vh - 66px);
}

.add-assets-btn-container {
  position: absolute;
  bottom: 24px;
  width: 100%;
  padding: 0px 24px 0px 24px;
}

.add-asset-btn {
  background: $Primary;
  color: #fff !important;
  border: 1px solid $Primary;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  margin-right: 16px;
  cursor: pointer;
}

.cancel-asset-btn {
  border: 1px solid $TextColor;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
}

.disable-asset-btn {
  background: $Whisper;
}

.invert {
  filter: invert(1);
}

#react-tiny-popover-container {
  z-index: 1;
}

.MuiTablePagination-selectIcon {
  width: 16px !important;
  right: 6px !important;
}

.predictive-capacity-legends {
  width: 40%
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .ms-margin-left-20px {
    margin-left: 20px;
  }

  .ms-margin-right-5px {
    margin-right: 5px;
  }

  .ms-margin-right-10px {
    margin-right: 10px;
  }

  .ms-margin-top-20px {
    margin-top: 20px;
  }

  .ms-margin-left-5px {
    margin-left: 5px !important;
  }

  .ddbblue {
    background-color: rgba(66, 136, 219, 0.3);
    width: 30px;
    height: 15px;
  }

  .ddbblue-light {
    background-color: rgba(67, 137, 218, 0.6);
    width: 30px;
    height: 15px;
  }

  .ddpink {
    background-color: rgba(199, 17, 96, 0.3);
    width: 30px;
    height: 15px;
  }

  .ddpink-light {
    background-color: rgba(197, 17, 97, 0.6);
    width: 30px;
    height: 15px;
  }

  .ddgrey {
    background-color: rgba(84, 110, 122, 0.3);
    width: 30px;
    height: 15px;
  }

  .ddgrey-light {
    background-color: rgba(82, 110, 123, 0.6);
    width: 30px;
    height: 15px;
  }

  .ddyellow {
    background-color: rgba(232, 139, 23, 0.3);
    width: 30px;
    height: 15px;
  }

  .ddyellow-light {
    background-color: rgba(230, 140, 22, 0.6);
    width: 30px;
    height: 15px;
  }

  .ddfmdgreen {
    background-color: rgba(38, 166, 154, 0.3);
    width: 30px;
    height: 15px;
  }

  .ddfmdgreen-light {
    background-color: rgba(36, 166, 155, 0.6);
    width: 30px;
    height: 15px;
  }

  .ddgreen {
    background-color: #90C290;
    width: 30px;
    height: 15px;
  }

  .ddgreen-light {
    background-color: #AFE3C0;
    width: 30px;
    height: 15px;
  }

  .MuiSvgIcon-root .MuiSelect-icon .MuiTablePagination-selectIcon {
    top: 0px !important;
  }

  .Mui .MuiSelect-select .MuiTablePagination-select .MuiSelect-selectMenu .MuiInputBase-input {
    padding: 3px 24px 6px 8px !important;
  }

  .gap {
    display: flex;
    gap: 8px;
  }

  .flex>span {
    padding-top: 15px !important;
  }

  .ms-model-img {
    height: 100% !important;
  }

  .ms-holder>span:nth-of-type(1) {
    display: block;
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  .ms-holder>span:nth-of-type(2) {
    display: block;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }

  .ms-holder>span:nth-of-type(3) {
    display: block;
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }

  .ms-holder>span:nth-of-type(4) {
    display: block;
    -ms-grid-row: 1;
    -ms-grid-column: 4;
  }

  .ms-holder>span:nth-of-type(5) {
    display: block;
    -ms-grid-row: 1;
    -ms-grid-column: 5;
  }

  .ms-overflow-y-visible {
    overflow-y: visible;
    padding-bottom: 100px;
  }

  .ms-table-pagination>div>div:nth-of-type(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 4;
    margin-left: 100%;
  }


  .graphwrapper-grid {
    margin-bottom: 30px !important;
  }

  .other-actions-text {
    white-space: nowrap;
  }

  .Other-Actions-Text {
    white-space: nowrap;
  }

  .asset-report-icon-pt {
    margin-left: 5px;
  }

  .ms-float-right {
    float: right;
  }

  .kpis-grid>div:nth-of-type(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  .kpis-grid>div:nth-of-type(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    margin-left: 8px;
  }

  .kpis-grid>div:nth-of-type(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    margin-left: 8px;
  }

  .kpis-grid>div:nth-of-type(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 4;
    margin-left: 8px;
  }

  .graphwrapper-grid>div:nth-of-type(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  .graphwrapper-grid>div:nth-of-type(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    margin-left: 8px;
  }

  .graphwrapper-grid>div:nth-of-type(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    margin-left: 8px;
  }

  .ms-padding-left-20 {
    padding-left: 20% !important;
  }

  .ms-display-flex {
    display: -ms-flexbox;
    justify-content: center;
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 13;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: 60px auto;
  padding: 0;
  border: 1px solid #888;
  width: 30%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
}

.modal-body {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  margin: auto;
  min-height: 50px;
  padding: 2px 16px;
  border-bottom: #e3dcdc 1px solid;
  border-top: #e3dcdc 1px solid;
  font-size: 0.9rem;
}

.modal-header {
  flex: 0 0 auto;
  justify-content: flex-start;
  display: flex;
}

.modal-header-text {
  margin: 10px 15px;
  font-weight: 600;
  color: #cc0000;
}

.modal-footer {
  justify-content: flex-end;
  flex: 0 0 auto;
  display: flex;
  margin-bottom: 10px;
  padding: 2px 10px;
}

.modal-footer-btn {
  height: 30px;
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

.word-break {
  word-break: break-all;
}

.triangle {
  position: absolute;
  bottom: -4px;
  left: calc(50% - 5px);
  right: auto;
  width: 10px;
  height: 10px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  border-left: 2px solid #146BD2;
  border-top: 2px solid #146BD2;
  background-color: white;
}

.asset-actions-btn-intuser .triangle,
.asset-actions-btn-partner .triangle {
  top: -4px;
  left: calc(80% - 5px);
  border: none;
  border-right: 2px solid #146BD2;
  border-bottom: 2px solid #146BD2;
}

.rectangle-asset-popup-box {
  background-color: white;
  border-radius: 8px;
  border: 2px solid #146BD2;
  font-size: 12px;
  text-align: center;
  padding: 0 8%;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%);
  -webkit-box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%);
}

.asset-popup-details {
  position: absolute;
  bottom: 40px;
  left: calc(50% - 100px);
  display: none;
  z-index: 1003;
}

.asset-popup-details-csv {
  position: absolute;
  bottom: 40px;
  display: none;
  z-index: 1003;
}

.asset-actions-btn-intuser .asset-popup-details,
.asset-actions-btn-partner .asset-popup-details {
  top: 40px;
  bottom: auto;
  left: auto;
  right: 50px;
}

.display-asset-popup {
  display: block;
}

.gap-large {
  align-items: center;
  gap: 4px;
}

.table-border {
  border: 1px solid $Whisper;
}

.hnas-modal {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 21;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.hnas-modal-content {
  background-color: white;
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  width: 90%;
  max-width: 57rem;
  height: 100%;
  // margin: 30px auto;
  border-radius: 4px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: 10;

}

.hnas-modal-scroll {
  height: calc(100% - 100px);
  overflow-y: auto;
}

.hnas-modal-block {
  display: block;
  height: 100%;
  width: 100%;
}

.hnas-modal-info {
  background-color: #888;
}

.menu-header {
  text-align: center;
  font-weight: 600 !important;
  margin: 8px 0px 0px 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  padding-bottom: 6px;
}

.card-item {
  padding-right: 0.5rem;
}

.site-alias-ellipsis {
  width: 66px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tbl {
  vertical-align: text-top !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.button-container {
  position: absolute;
  bottom: 12px;
  right: 0px
}

.button-color {
  color: $Primary;
}

.unselect-button-mobile {
  color: $Red;
}

.max-w-25 {
  max-width: 100px;
}

.asset-details-locationText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;
  color: #146BD2 !important;

}

.asset-detail-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

.asset-details-locationText span {
  color: $TextColor;
}

.hitachi-logo-span {
  border-right: 1px solid #000;
  margin-right: 8px;
}

@media screen and (max-width: 768px) {

  .subtabs-main-sec {
    padding-top: 15px;
  }

  .hide-menu-icon {
    display: none;
  }

  .topnav-middle {
    padding-top: 4px;
  }

  .header-text-div {
    flex-direction: column;
    font-size: 14px;
  }

  .header-text {
    margin-right: 0;
  }

  #permission_slider_id,
  #add_asset_slider_id,
  #olympus_slider_id,
  #multiShare_permission_slider_id {
    margin-top: 67px;
    height: calc(100% - 55px);
  }

  .inventory-asset-card-container {
    grid-template-columns: 1fr;
  }

  .subtabs-sec {
    margin-right: 6% !important;
  }
}


@media (min-width: 600px) {

  .padding-md-4 {
    padding-left: 4%;
  }

  .padding-top-md-10 {
    padding-top: 10%;
  }

  .word-break-not-small-device {
    word-break: break-word;
  }

  .grid-without-cloud-connection {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 25px 32px;
  }
}

.asset-pdf {
  font-size: 12px
}


.last-refresh-date {
  float: right;
  padding-top: 5px;
  padding-right: 5px;
  white-space: nowrap;
}

.last-refresh-date-for-graphs {
  float: right;
  color: #999999;
  font-size: 12px;
  padding-top: 5px;
  padding-right: 5px;
  font-weight: 700;
  font-style: italic;
  position: absolute;
  right: 12px;
  bottom: 16px;
  line-height: 18px;
}

.padding-right-bottom-20 {
  padding: 0px 20px 20px 0px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.align-self-end {
  align-self: flex-end;
}

.no-wrap {
  white-space: nowrap;
}

.asset-actions-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  z-index: 1;
}

.margin-right-10 {
  margin-right: 10px;
}

.asset-action-text {
  font-size: 14px;
  margin-left: 0.4em;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.error-btn {
  background-color: #CB3B30 !important;
  color: #FFFFFF !important;
}

.remove-hover-effect:hover {
  background-color: transparent !important;
}

.popover-header {
  display: flex;
}

.loading-button {
  position: relative;
}

.button-loader {
  position: absolute;
  right: 20%;
  top: 23%;
}

.buttons-fixed-bottom-right {
  position: fixed !important;
  bottom: 60px;
  z-index: 10;
  right: 38px;
  display: grid;
  grid-gap: 30px;
}

.no-inventory-message {
  font-size: 16px;
  font-weight: 600;
  color: $TextColor;
  margin-top: 15px;
}

.multi-steps-panel {
  padding: 20px 16px 16px 16px;
  background: #F4F5F5;
}

.cancel-btn {
  background-color: $Azure !important;
  border: 1px solid #CCCED0 !important;
  color: $TextColor !important;
  border-radius: 2px !important;
}

.permission-cancel-btn {
  color: $TextColor !important;
  background-color: #fff !important;
  border: 1px solid #fff !important;
}

.primary-border-btn {
  border: 1px solid #146BD2 !important;
}

.asset-report-icon-card {
  margin-left: 10px;
  margin-top: 6%;
}

.asset-report-icon-size {
  height: 20px;
  width: 20px;
}

.cursor-default {
  cursor: default !important;
}

.status-message {
  overflow-x: auto;
  max-height: 50px;
  margin-left: 2px;
}

.no-padding-right {
  padding-right: 0;
}

.error-text {
  color: #d32f2f;
}

.margin-top-0 {
  margin-top: 0px;
}

.margin-top-3 {
  margin-top: 3px;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.warning-border {
  border-bottom: 1px solid #e68c17;
}

.capitalize {
  text-transform: capitalize;
}

.subtabs-sec {
  font-size: 16px;
  padding: 0px 24px 6px 24px;
  line-height: 24px;
  letter-spacing: 0em;
  color: $TextColor;
  font-weight: 400;
  cursor: pointer;
  margin-right: 3%;
  text-align: center;
}

.subtabs-sec.active {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  background: transparent;
  padding: 0px 24px 6px 24px;
  color: $TextColor;
  border-bottom: 2px solid $TextColor;
}

.subtabs-main-sec {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #D1D1D1;
}

.disable-subtab {
  pointer-events: none;
  color: #00000061;
}

.margin-left-50 {
  margin-left: 50px;
}

.connection-status-icons {
  margin-right: 5px;
  margin-top: 2px;
}

.connection-status-img {
  margin-right: 5px;
  height: 30px;
  width: 30px;
}

.flex-direction-column {
  flex-direction: column;
}

.loader-text {
  color: grey;
}

.loader-text:after {
  content: "Loading";
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loading-text {
  0% {
    content: "Loading"
  }

  25% {
    content: "Loading."
  }

  50% {
    content: "Loading.."
  }

  75% {
    content: "Loading..."
  }
}

.column-gap-4 {
  column-gap: 4px;
}

.alerts-icon {
  position: relative;
  right: 16px;
}

.overflow-hidden {
  overflow: hidden !important;
}

.top-0 {
  top: 0;
}

.itallic-small-font {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: $TextColor;
}

.microcode-popover {
  position: absolute;
  top: 40px;
  left: 6px;
  z-index: 25;
}


.microcode-popover .triangle-up {
  position: absolute;
  top: 4px;
  margin-left: 90px;
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  border-right: 2px solid #171717;
  border-bottom: 2px solid #171717;
  background-color: #171717;
}

.microcodr-popover-msg {
  background: #171717;
  color: #FFFFFF;
  width: 200px;
  border-radius: 8px;
  font-size: 12px;
  text-align: center;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%);
  -webkit-box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%);
}

.text-decoration-underline {
  text-decoration: underline;
}

.negative-margin-top-3 {
  margin-top: -3px;
}

.inventory .microcode-popover {
  top: 33px;
}

.share-permission-stepper .MuiStepLabel-iconContainer,
.share-permission-stepper .Mui-active .MuiStepConnector-line,
.share-permission-stepper .Mui-completed .MuiStepConnector-line {
  border-color: #478B1A;
}

.share-permission-stepper .MuiStepConnector-lineHorizontal {
  border-top-width: 3px;
}

.step-circle {
  height: 15px;
  width: 15px;
  border: 1px solid #1976d2;
  border-radius: 50%;
  background: #1976d2;
}

.previous-btn {
  color: $TextColor !important;
  background-color: $Azure !important;
  border: 1px solid #CCCED0 !important;
  // margin-right: 12px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
}

.shared-ids-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: auto;
  // max-height: calc(100vh - 446px);
  // overflow: auto;
  width: 100%
}

.shared-permission-slider {
  padding: 24px 16px 24px 16px;
}

.shared-permission-slider .permission-checkbox .Mui-checked {
  color: $TextColor
}

.permission-checkbox>svg {
  width: 16px;
  height: 16px;
}

.permission-checkbox>span>svg {
  width: 16px;
  height: 16px;
}

.common-header-checkbox>svg {
  width: 16px;
  height: 16px;
}

.shared-ids {
  display: inline-block;
  // padding: 12px 0px 12px 0px;
}

.shared-ids>span {
  background-color: #D8E6F1;
  padding: 2px 6px 2px 6px;
}

.permission-btn-container {
  border-top: 1px solid #D1D1D1;
  padding: 12px;
}

.bottom-aligned {
  position: absolute;
  bottom: 12px;
}

.margin-top-20-percent {
  margin-top: 20%;
  margin-bottom: 4%;
}

.margin-top-bottom-3-percent {
  margin: 3% 0;
}

.input-with-btn {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 15px;
}

.slider-table {
  max-height: 450px;
  overflow-y: auto;
  margin-top: 4%;
}

.MuiStepIcon-text {
  font-size: 14px !important;
}

.permission-slider-table {
  height: calc(100vh - 248px);
  overflow-y: auto;
  margin-top: 10%;
}

.slider-table .MuiTableRow-root .MuiTableCell-body {
  padding: 10px;
}

.permission-slider-table .MuiTableRow-root .MuiTableCell-body {
  padding: 10px;
}

.warning-status {
  background-color: #fff4e5 !important;
}

.height-430 {
  min-height: 430px;
}


.selected-stepper-icon {
  position: relative;
  top: 3px;
  left: 3px;
}

.selected-stepper-icon .Mui-completed {
  fill: #478B1A;
}

.outer-circle {
  width: 31px;
  height: 31px;
  border: 1px solid $TextColor;
  border-radius: 16px;
}

.stepper-icon>span>svg {
  fill: #999999;
}

.selected-stepper-icon>span>svg {
  fill: $TextColor;
  width: 24px;
  height: 24px;
}

.selected-card {
  box-shadow: 0 0.3rem 2rem rgb(0 0 0 / 10%);
}

.inventory-alert {
  background-color: #f7e2c4;
  margin: 15px 0;
  padding: 10px;

}

.box-shadow-hover {
  border-bottom: 2px solid #FBFCFC;
  border-left: 2px solid #FBFCFC;
  border-right: 2px solid #FBFCFC;
}

.box-shadow-hover:hover {
  border: 2px solid #146bd2;
  border-top: 2px solid #146bd2 !important;
}

.selected-card-icon {
  color: #3fc3b0;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

#header-popover .MuiDivider-fullWidth {
  border-bottom-width: 2px !important;
}

.hide-for-large-device {
  display: none;
}

.hide-largeScreen {
  display: none;
}

.hide-for-large-device-header {
  display: none;
}

.remove-button-hover-effect:hover {
  box-shadow: none !important;
}

.circle-button-hover-effect {
  border-radius: 30px !important;
  padding: 10px !important;
  width: max-content;
  margin-left: auto !important;
}

.card-button-hover-effect {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}

.circle-button-hover-effect.btn-primary,
.circle-button-hover-effect.btn-primary:hover {
  color: #fff;
  background-color: #146BD2;
  box-shadow: 0px 0px 8px 8px rgb(20, 107, 210, 20%) !important;
}

.circle-button-hover-effect.btn-white,
.circle-button-hover-effect.btn-white:hover {
  background-color: #fff;
  color: #121212;
  box-shadow: 0px 0px 8px 8px rgb(255, 82, 82, 20%) !important;
}

.circle-button-hover-effect span.button-text,
.inventory-asset-card .card-button-hover-effect span {
  font-size: 14px;
  z-index: 1;
  white-space: nowrap;
  margin-right: 0px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  visibility: none;
  width: auto;
  max-width: 0px;
  /* This is the fixed unit value */
  opacity: 0;
  transition: all 0.5s;
  box-sizing: border-box;
  padding-right: 0px;
  text-decoration: none;
}

.inventory-asset-card .card-button-hover-effect span {
  font-weight: 600;
  color: #3fc3b0;
  margin-left: 4px;
}

.circle-button-hover-effect:hover span.button-text,
.inventory-asset-card:hover .card-button-hover-effect span {
  opacity: 1;
  width: auto;
  max-width: 500px;
}

.circle-button-hover-effect:hover span.button-text {
  padding: 0 10px;
}

.disabled-text-color {
  color: #928c8c !important;
}

.asset-details-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $Azure;
  border-radius: 6px;
  border: 1px solid #CCCED0;
  padding: 10px 12px;
  flex-wrap: wrap;
  gap: 6px;
}

.input-with-btn .search-input-wrapper {
  grid-template-columns: auto;
  display: flex;
}

.overflow-auto-imp {
  overflow: auto !important;
}

.selectedCreateVolumeCard {
  background-color: #00467A;
}

.disable-card {
  opacity: 0.7;
  pointer-events: none;
}

.create-volume-card-container {
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  grid-gap: 20px;
}

.create_volume_btn {
  color: white !important;
  background: #999999 0% 0% no-repeat padding-box !important;
  border: 1px solid #6C6B6B !important;
  border-radius: 2px;
}

.create-volume-card-header {
  background: #F4F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #CCCED0;
  border-radius: 2px 2px 0px 0px;
  opacity: 1;
}

.create-volume-capacity-txtbox {
  width: 162px;
  height: 25px;
  background: $Azure 0% 0% no-repeat padding-box;
  border: 1px solid #6C6B6B;
  border-radius: 2px;
}

.create-volume-close-btn {
  width: 4rem;
  border: 1px solid $TextColor !important;
  text-align: center;
  color: $TextColor !important;
  border-radius: 2px !important;
  padding: 8px 16px !important;
}

.create-volume-selected-card-header {
  background: $Primary 0% 0% no-repeat padding-box;
  border: 1px solid $Primary;
  border-radius: 2px 2px 0px 0px;
  opacity: 1;
}

.create-vol-card {
  border: 1px solid #CCCED0;
  border-radius: 2px;
}

.create-vol-selected-card {
  border: 1px solid $Primary;
  border-radius: 2px;
}

.creds-txtbox {
  width: 370px;
  height: 25px;
  background: #F4F5F5 0% 0% no-repeat padding-box;
  border: 1px solid #6C6B6B;
  border-radius: 2px;
}

.display-none {
  display: none;
}

.green-color {
  color: green !important;
}

.font-size-20 {
  font-size: 20px;
}

.alert-summary-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}


.alert-summary-icons {
  height: 21px;
  width: 21px;
  margin-left: 2px;
}

.asset-report-drives-grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  border-bottom: 1px solid lightgrey;
}

.asset-report-pool-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  font-size: 0.9rem;
  border-bottom: 1px solid lightgrey;
  line-height: 18px;
  font-weight: 600;
  color: $TextColor;
}

.asset-report-parity-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid lightgrey;
 // border-top: 1px solid lightgrey;
}

.asset-report-parity-grid-container-for-storage-nodes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
  border-bottom: 1px solid lightgrey;
 // border-top: 1px solid lightgrey;
}

.server-module-grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  font-size: 14px;
  border-bottom: 1px solid lightgrey
}

.asset-report-accordion .MuiAccordionSummary-content {
  display: grid !important;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  font-size: 14px;
  margin: 0 !important;
  border-bottom: 1px solid lightgrey;
  background: #fafafa;
  line-height: 21px;
  font-weight: 400;
}

.asset-report-parity-accordion .MuiAccordionSummary-content {
  display: grid !important;
  font-size: 14px;
  margin: 0 !important;
  border-bottom: 1px solid lightgrey;
  background: #fafafa;
  line-height: 21px;
  font-weight: 400;
}

.asset-enclosure-accordion .MuiAccordionSummary-content {
  display: grid !important;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  font-size: 14px;
  margin: 0 !important;
  border-bottom: 1px solid lightgrey;
  background: #fafafa;
  line-height: 21px;
  font-weight: 400;
}

.asset-server-module-accordion .MuiAccordionSummary-content {
  display: grid !important;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  font-size: 14px;
  margin: 0 !important;
  border-bottom: 1px solid lightgrey;
  background: #fafafa;
  line-height: 21px;
  font-weight: 400;
}

.asset-report-accordion .Mui-expanded {
  border-bottom: none;
}

.asset-report-parity-accordion .Mui-expanded {
  border-bottom: none;
}

.asset-enclosure-accordion .Mui-expanded {
  border-bottom: none;
}

.asset-server-module-accordion .Mui-expanded {
  border-bottom: none;
}

.asset-enclosure-accordion .MuiCollapse-wrapper {
  border: 1px solid lightgrey;
  margin-top: 16px;
  margin-bottom: 16px;
}

.server-module-nested-tables {
  border: 1px solid lightgrey;
  border-bottom: none;
  margin-top: 16px;
  margin-bottom: 16px;
}

.asset-server-module-accordion .MuiCollapse-wrapper {
  margin-top: 16px;
  margin-bottom: 16px;
}

.asset-report-accordion:before {
  background-color: transparent !important;
}

.asset-report-parity-accordion:before {
  background-color: transparent !important;
}

.asset-enclosure-accordion:before {
  background-color: transparent !important;
}

.asset-server-module-accordion:before {
  background-color: transparent !important;
}

.asset-report-accordion {
  box-shadow: none !important;
  margin: 0 !important;
}

.asset-report-parity-accordion {
  box-shadow: none !important;
  margin: 0 !important;
}

.asset-server-module-accordion {
  box-shadow: none !important;
  margin: 0 !important;
}

.asset-enclosure-accordion {
  box-shadow: none !important;
  margin: 0 !important;
}

.accordion-summary {
  min-height: auto !important;
}

.minHeight-300 {
  min-height: 300px;
}

.background-color-grey {
  background: #f3f3f3;
  font-weight: bold;
}

.italic-font {
  font-style: italic;
  font-weight: bold;
}

.asset-details-bodder-top {
  border-top: 1px solid #CCCED0;
  padding-top: 9px;
}


.min-height-42 {
  min-height: 42px;
}

.no-data-sec {
  border-bottom: 1px solid lightgrey;
  padding: 10px 15px;
  font-size: .9rem;
}

.olympus-popover {
  max-width: 500px;
  padding: 20px;
  overflow-y: auto;
}

.selected-graph {
  box-shadow: 0 0.3rem 2rem rgb(0 0 0 / 40%);
}

.selected-tile {
  background-color: #D3E3F6;
}

.chart-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.missing-data-wrapper {
  padding-top: 5px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.missing-data-wrapper button {
  background-color: transparent;
  color: #146BD2 !important;
  font-size: 12px;
  text-decoration: underline;
  border: none;
  outline: none;
  cursor: pointer;
}


.text-transform-capitalize {
  text-transform: capitalize;
}

.progress-container {
  min-height: 185px;
}

.progress-container:last-child {
  border-bottom: none;
}

.legend-block {
  width: 50%
}

.legend-block-full {
  width: 100%
}

.typography-xsLabel {
  color: $TextColor;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  overflow-wrap: break-word;
  margin: 0;
}

.combined-storage-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 8px 16px;
}

.icons-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d1d1;
  padding: 0px 16px 0px 16px;
}

.pop-over-hsae .storage-icon-type {
  cursor: pointer;
  margin-left: 10px;
}

.pool-parity-container,
.licesed-installed-container,
.virtual-storage-container {
  min-height: 408px;
}

.align-left {
  text-align: left;
}

.ids-height {
  height: 412px;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.px-16 {
  padding: 0 16px;
}

.h-100 {
  height: 100%
}

.space-between {
  justify-content: space-between;
}

.asset-report-collapsible-table .table-header th {
  font-weight: 600 !important;
  font-size: 12px;
  border-bottom: 1px solid lightgrey;
  text-align: left !important;
}

.asset-report-collapsible-table .table-header .right-aligned {
  text-align: right !important;
}

.asset-report-collapsible-table .table-subheader {
  font-size: 0.9rem;
  margin: 0 !important;
  border-bottom: 1px solid lightgrey;
  background: #fafafa;
}

.sub-table {
  border: 1px solid #d3d3d3;
  margin-bottom: 16px;
  margin-top: 16px;
}

.sub-table-rows {
  background: #fafafa;
  border-bottom: 1px solid #d3d3d3;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.user-name-initials {
  background: $TextColor;
  color: #FFFFFF;
  border-radius: 50%;
  padding: 5px;
  margin-right: 5px;
}

.shared-permission-sec {
  background: #D7E6CF;
  padding: 2px 12px;
  font-size: 10px;
  line-height: 12px;
  margin-left: 18px;
}

.no-access-sec {
  background: #F4D3D4;
  padding: 2px 12px;
  white-space: nowrap;
  font-size: 10px;
  line-height: 12px;
  margin-left: 12px;
}

.not-applicable-sec {
  margin-left: 12px;
}

.border-radius-40 {
  border-radius: 40px;
}

#AssetsPermissionTblContainerId,
#AssetsInventoryTblContainerId,
#AlertsTableContainerId {
  width: 100%;
  min-width: 100%;
  overflow: auto;
  min-height: 211px;
}

.border-bottom-none {
  border-bottom: none;
}

.hyperlink {
  color: $Primary !important;
  text-decoration: underline;
}

.view-report-btn {
  color: $Primary !important;
  border: 1px solid $Primary;
  border-radius: 2px;
  padding: 4px 16px 4px 16px;
}

#remove-asset-popover .MuiPaper-root {
  border-radius: 0px 0px 4px 4px;
  border-top: 4px solid $Red;
}

.remove-asset-btns-container {
  padding: 12px 16px 12px 16px;
}


.csv-asset-btn {
  background: $Primary;
  color: #fff !important;
  border-radius: 4px;
  padding: 8px 24px 8px 24px;
  margin-right: 12px;
}

.remove-asset-btn {
  background: $Red;
  color: #fff !important;
  border-radius: 4px;
  padding: 8px 24px 8px 24px;
  margin-right: 12px;
}

.divider {
  border-bottom: 1px solid #D1D1D1;
}

@media screen and (min-width:855px) and (max-width:1024px) {
  .file-analytics {
    margin-top: 36px;
  }

  .file-analytics-header {
    margin-top: 12px;
  }

  .file-analytics-system {
    margin-top: 24px;
  }

}


@media screen and (min-width:804px) and (max-width:854px) {
  .margin-file {
    margin-top: 24px;
  }
}

@media screen and (max-width: 1920px) and (min-width: 1445px) {
  .hnas-popup {
    max-height: 190px !important;
    height: max-content !important;
  }

  .hnas-modal-content {
    max-width: none !important;
    // height: max-content !important;
    display: table;
  }

  .second-tbl {
    width: -webkit-fill-available !important;
  }

  .nodata-msg {
    margin: 12px 473px 220px !important;
  }
}

@media screen and (min-width: 1026px) {
  .asset-details-sec {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 25px 32px;
  }
}

@media screen and (max-width: 960px) and (min-width: 600px) {

  .OtherAction-MainDiv {
    padding-top: 15px;
  }

  .Other-ActionIcon-Sec {
    display: block;
  }

  .modal-content {
    width: 50%;
  }

  .eula-content {
    width: 70% !important;
  }

  .eula-text {
    height: calc(100vh - 280px);
  }

  .inventory-sort-select {
    padding-left: 0;
    width: 14.125rem;
  }

  .only-for-tab-device {
    display: block;
  }

  .inventory-subheader-right-tableview {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }



  .model-image {
    width: 26%
  }

  .hcp-location-grid {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .inventory-subheader-view-selector {
    padding-bottom: 0 !important;
  }

}

@media screen and (max-width: 768px) {
  .topnav-middle {
    padding-top: 4px;
  }

  .header-text-div {
    flex-direction: column;
    font-size: 14px;
  }

  .header-text {
    margin-right: 0;
  }

  #permission_slider_id,
  #add_asset_slider_id,
  #olympus_slider_id,
  #multiShare_permission_slider_id {
    margin-top: 64px;
    height: calc(100% - 55px);
  }

  .inventory-asset-card-container {
    grid-template-columns: 1fr;
  }

  .subtabs-sec {
    margin-right: 6% !important;
  }
}

@media (min-width: 600px) {

  .padding-md-4 {
    padding-left: 4%;
  }

  .legends-gap {
    margin-bottom: 12px;
  }

  .padding-top-md-10 {
    padding-top: 10%;
  }

  .word-break-not-small-device {
    word-break: break-word;
  }

  .grid-without-cloud-connection {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 25px 32px;
  }
}

@media (min-width: 960px) {
  .other-actions-text.tab-responsive {
    font-size: 11px;
  }
}

@media screen and (max-width: 1164px) and (min-width: 600px) {
  .grid-without-cloud-connection {
    grid-template-columns: auto !important;
  }

  .asset-details-sec {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 25px;
  }

  .asset-details-sec-for-hcp {
    display: grid;
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 1919px) and (min-width: 1706px) {
  .margin-top-20-percent {
    margin-top: 24%;
  }
}

@media screen and (max-width: 2559px) and (min-width: 1920px) {
  .margin-top-20-percent {
    margin-top: 47%;
  }
}

@media screen and (max-width: 3839px) and (min-width: 2560px) {
  .height-430 {
    min-height: 430px;
    height: 800px;
  }
}

@media screen and (min-width: 3840px) {
  .height-430 {
    min-height: 430px;
    height: 1340px;
  }
}

@media screen and (min-width: 1270px) {

  .inventory-asset-card-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .graph-container {
    width: 460px;
  }

}

@media(max-width:450px) {
  .inventory-subheader-right-tableview {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    justify-content: space-between;
    grid-template-rows: auto auto;
  }

  .subtabs-sec {
    margin-right: 0% !important;
    padding: 0px 16px 6px 24px;
  }

  .subtabs-sec.active {
    padding: 0px 0px 6px 0px;
    margin-right: 16px !important;
  }
}

.forcastLegends {
  width: .5rem;
  height: .5rem;
  border-radius: .5rem;
}

.forecast-dotted-legends {
  width: 24px;
  height: 3px;
  background: repeating-linear-gradient(to right,
      #95AFE8 0,
      #95AFE8 6px,
      transparent 2px,
      transparent 8px);
}

@media screen and (max-width: 768px) and (min-width: 200px) {

  #AssetsInventoryTblId,
  .pagination {
    //width: 65rem;
    min-width: 9rem;
    overflow: auto;
  }

}

@media screen and (max-width: 1380px) and (min-width: 200px) {
  #AssetsPermissionTblId {
    overflow: auto;
  }
}


@media screen and (max-width: 1212px) and (min-width: 981px) {
  .dashboard_header_selector-container {
    max-width: 100% !important;
  }

  .dashboard_header_selector {
    max-width: 100% !important;
  }

  .dashboard_header_name {
    max-width: 100%;
    flex-basis: 100%;
    flex-grow: 0;
  }

  .mt-12 {
    margin-top: 12px;
  }

  .icon-width {
    width: 100%;
  }

  .alerts-and-permission {
    padding: 4px;
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

}

@media screen and (max-width: 980px) and (min-width: 200px) {

  .mt-12 {
    margin-top: 12px;
  }

  .margin-right-10-imp {
    margin-right: 5px !important;
  }

  .dashboard_header_selector-container {
    max-width: 100% !important;
  }

  .dashboard_header_selector {
    max-width: 100% !important;
  }

  .header_subcontent_width-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hide-for-small-device-header {
    display: none !important;
  }

  .hide-for-large-device-header {
    display: block !important;
  }

  .hamburger-menu span {
    display: block;
    width: 30px;
    height: 3px;
    background-color: #151719;
    margin-bottom: 4.5px;
    float: none;
    padding: 0;
  }

  .topnav-right {
    margin-top: 0px;
  }

  #MenuSlider.open-slider {
    left: 0px;
    z-index: 1002;
  }

  #MenuSlider {
    position: fixed;
    width: 250px;
    height: 100%;
    background-color: white;
    z-index: 15;
    padding: 25px 15px;
    left: -290px;
    transition: all 500ms linear;
  }

  .for-small-device {
    display: flex !important;
  }

  .menu-slider-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 20px;
    padding-bottom: 12px;
  }

  .menu-slider-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% - 75px);
  }

  .active-slider-tab {
    border-left: 5px solid $Red;
    color: $Red;
    font-weight: 600;
  }

  #MenuSlider ul li {
    list-style: none;
    padding: 10px 10px;
    margin-bottom: 20px;
  }

  #MenuSlider ul {
    padding: 0;
  }

  #MenuSlider .hitachi-logo-span {
    padding-right: 8px;
    border-right: 1px solid #000;
    margin-right: 8px;
  }

  #MenuSlider .header-text-div {
    flex-direction: column;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .inventory-sub-header {
    margin-top: 0px;
  }

  .inventory-subheader-search {
    max-width: 100% !important;
  }

  .menu-slider-footer-text-2 {
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
  }

  .menu-slider-footer-text-1 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }

  #AlertsTableId {
    width: 65rem;
    min-width: 65rem;
    overflow: auto;
  }

  #AlertsContainerId .storage-icons-container {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .height-after-login {
    height: calc(100vh - 110px) !important;
  }

  .height-before-login {
    height: calc(100vh - 51px) !important;
  }
}

@media (max-width: 610px) {
  .asset-report-storageNode {
    overflow: auto;
    display: block !important;
  }

  .justify-content-end-mobile {
    justify-content: flex-end;
  }
}

@media (min-width: 2460px) and (max-width: 3560px) {
  @-moz-document url-prefix() {
    tr:first-child {
      border-top: 3px solid #CCCED0;
    }
  }
}

@media (min-width: 3570px) {
  @-moz-document url-prefix() {
    tr:first-child {
      border-top: 4.5px solid #CCCED0;
    }
  }
}

@media (min-width: 1170px) and (max-width: 1280px) {
  #HnasAssetsDetailsTblId {
    .hnas-ev-sys-width {
      width: 142%;
    }
  }

  .predictive-capacity-legends {
    justify-content: center;
    margin-top: 8px;
    display: flex;
    width: 100%;
  }
}

@media (min-width: 1281px) and (max-width: 1423px) {
  #HnasAssetsDetailsTblId {
    .hnas-ev-sys-width {
      width: 110%;
    }
  }
}


@media (min-width: 600px) {
  .dashboard_header_name {
    max-width: 100%;
    flex-basis: 100%;
    flex-grow: 0;
  }




  .dashboard_header_selector {
    flex-grow: 0;
    max-width: 37.5%;
    flex-basis: 37.5%;
  }

  .inventory-subheader-search {
    /* padding: 4px; */
    flex-grow: 0;
    max-width: 37.5%;
    flex-basis: 37.5%;
  }

  .inventory-subheader-spacer {
    flex-grow: 0;
    max-width: 12.5%;
    flex-basis: 12.5%;
  }

  .inventory-subheader-sort-selector,
  .inventory-subheader-actions {
    padding: 15px;
    flex-grow: 0;
  }

  .inventory-subheader-view-selector {
    flex-grow: 0;
  }

  .inventory-asset-card-container {
    grid-template-columns: 1fr 1fr;
  }

  .asset-actions-button-container {
    flex-grow: 0;
    padding: 15px;
    margin: 0;
    box-sizing: border-box;
  }

  .asset-info-with-img {
    -ms-grid-columns: 1fr 8fr;
    grid-template-columns: 1fr 8fr;
  }

  .asset-info-without-img {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }
}

@media (min-width: 960px) {
  .dashboard_header_name {
    max-width: 21%;
    flex-basis: 21%;
    flex-grow: 0;
  }

  .dashboard_header_selector {
    flex-grow: 0;
    max-width: 24%;
    flex-basis: 24%;
  }

  .inventory-subheader-search {
    /* padding: 4px; */
    flex-grow: 0;
    max-width: 100%;
    margin: auto 0;
  }

  .inventory-subheader-spacer {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
  }

  .inventory-subheader-sort-selector,
  .inventory-subheader-actions {
    padding: 15px;
    flex-grow: 0;
  }

  .inventory-subheader-view-selector {
    flex-grow: 0;
    align-self: flex-end;
  }

  .inventory-asset-card-container {
    grid-template-columns: 1fr 1fr;
  }

  .asset-actions-button-container {
    flex-grow: 0;
    padding: 15px;
    margin: 0;
    box-sizing: border-box;
  }

  .margin-left-20-for-large-device {
    margin-left: 20%;
  }




  /* .asset-details-sec-for-hcp {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 25px 32px;
  } */



  .asset-details-sec-cloud.reduce-location {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 25px;
  }

  .asset-details-sec-cloud {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 25px;
  }
}

@media (min-width: 1271px) {
  .hide-for-big-screen-device {
    display: none;
  }
}

@media (min-width: 1270px) {
  .search-for-hnas {
    width: 100%
  }

  .asset-details-sec-for-hcp {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 25px 32px;
  }

  .asset-details-sec.reduce-location {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 25px;
  }
}

@media (max-width: 1269px) {
  .search-for-hnas {
    width: 100%;
  }

  .padding-for-system-drive-mobile {
    padding-top: 43px;
  }
}

@media (min-width: 1290px) {
  .inventory-subheader-search {
    max-width: 32%;
  }

  .dashboard_header_name {
    max-width: 25%;
    flex-basis: 25%;
    flex-grow: 0;
  }


  .inventory-subheader-spacer {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
  }

  .inventory-subheader-sort-selector,
  .inventory-subheader-actions {
    padding: 15px;
    flex-grow: 0;
  }

  .inventory-subheader-view-selector {
    flex-grow: 0;
    padding: 12px;
  }

  .inventory-asset-card-container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .asset-actions-button-container {
    flex-grow: 0;
    padding: 15px;
    margin: 0;
    box-sizing: border-box;
  }

  .asset-info-with-img {
    -ms-grid-columns: 1fr 8fr;
    grid-template-columns: 1fr 8fr;
  }

  .asset-info-without-img {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }
}


@media (min-width: 1920px) {
  .dashboard_header_name {
    max-width: 25%;
    flex-basis: 25%;
    flex-grow: 0;
  }

  .dashboard_header_selector {
    /* padding: 15px; */
    max-width: 24%;
    flex-basis: 24%;
    flex-grow: 0;
  }

  /* @-moz-document url-prefix() {
    tr:first-child {
      border-top: 1.5px solid #CCCED0;
    }
  } */
}

.hnas-popup-second-table {

  max-height: 10rem !important;
  height: max-content !important;
}

@media (max-width: 1270px) {
  .margin-bottom-adr{
    margin-bottom: 32px;
  }
  .for-large-device {
    display: none !important;
  }

  .hide-for-small-screen-device {
    display: none;
  }

  .for-medium-device {
    display: block !important;
  }

  .user-dropdown-details {
    position: absolute;
    top: 45px;
    z-index: 12;
    right: 25px;
    display: none;
  }

  .triangle-up {
    position: absolute;
    top: -6px;
    margin-left: 185px;
    left: 0;
    right: 0;
    width: 10px;
    height: 10px;
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    border-right: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
    background-color: white;
  }

  .topnav-middle {
    margin-left: 37%;
  }

  .rectangle-user-box {
    background-color: white;
    width: 200px;
    height: 132px;
    border: 1px solid #DEDEDE;
    font-size: 14px;
    padding: 1% 8%;
  }

  .display-user-dropdown {
    display: block;
  }

  .aperture-logo {
    width: 80px;
    height: 15px;
    margin-top: 3px;
  }

  .other-actions-div {
    display: flex;
  }

  .asset-report-icon-pt {
    margin-left: 5px;
  }

  .other-actions-text {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  .Other-Actions-Text {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  .asset-table-style {
    padding: 0;
  }

  .flex-one-medium-device {
    flex: 1;
  }

  .eula-content {
    width: 70% !important;
  }

  .eula-text {
    height: calc(100vh - 260px);
  }

  #permission_slider_id,
  #add_asset_slider_id,
  #olympus_slider_id,
  #multiShare_permission_slider_id {
    margin-top: 64px;
  }
}

@media(max-width: 768px) {
  .hide-smallScreen {
    display: none !important;
  }

  .hide-largeScreen {
    display: block !important;
  }

  .hide-for-large-device {
    display: block !important;
  }

  .hide-for-small-device {
    display: none !important;
  }

  .Other-ActionIcon-Sec {
    flex-direction: row;
    padding: 8px 15px;
    margin-left: 0px;
    font-size: 14px;
  }

  .Asset-ReportIcon-Pt {
    margin-left: -3px;
  }

  .Other-ActionIcon-Sec svg,
  .Other-ActionIcon-Sec img {
    width: 27px;
    height: 27px;
    margin-right: 8px;
  }

  .asset-detail-img-width {
    min-width: 100px;
    width: auto;
  }

  .padding-top-md-10 {
    padding-top: 0% !important;
  }

}

@media (min-width: 1920px) {
  .HvLabFooter-root {
    max-width: 1920px;
  }

  .aperture-main-content-height {
    margin: 0 auto;
    /* max-width: 1920px; */
    background-color: #F5F5F5;
  }

  .asset-detail-img-width {
    width: 75%;
  }

  .inventory-asset-card-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
  }



  .hnas-modal-content {
    max-width: none !important;
    // height: max-content !important;
    display: table;
  }

  .second-tbl {
    width: -webkit-fill-available !important;
  }

  .nodata-msg {
    margin: 12px 906px 220px !important;
  }

  .flex-important {
    display: flex;
    flex-direction: row;
    gap: 50px;
  }

  .flex-important-file {
    display: flex;
    flex-direction: column;
  }

  .border-bottom {
    border-bottom: 1px solid #ccced0;
  }

  .w-50 {
    width: auto !important;
  }

  .alert-summary-container-large-file {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px
  }

  .table-icon-for-large {
    display: flex;
    gap: 8px
  }

  .microcode {
    flex-direction: column;
    align-items: flex-start;

  }

  .gap-icon {
    gap: 3px
  }

  .margin-top-login {
    margin-top: 3px
  }
}

@media screen and (min-width: 200px) and (max-width: 1180px) {
  .credentials-layout-cls {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media (min-width: 701px) and (max-width: 1024px) {
  .permission-table-search {
    width: 100%;
  }

  .icons-container {
    display: block;
    padding: 0px 8px 0px 8px;
  }

}

@media (min-width: 1705px) and (max-width: 1919px) {
  .hnas-popup-second-table {
    max-height: 12rem !important;
  }

}

@media (min-width: 1920px) and (max-width: 2559px) {
  .hnas-popup {

    max-height: 234px !important;
    height: max-content !important;
  }

  .hnas-popup-second-table {

    max-height: 292px !important;
    height: max-content !important;
  }
}

@media (min-width:2560px) {
  .hnas-popup {

    max-height: 378px !important;
    height: max-content !important;
  }

  .hnas-popup-second-table {

    max-height: 421px !important;
    height: max-content !important;
  }
}

@media (max-width: 1024px) {
  .storage-type-container {
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 700px) {
  .chart-footer {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .chart-footer .missing-data-wrapper {
    gap: 0;
  }

  .icons-container {
    display: block;
  }

  .permission-table-search {
    width: 97%;
  }
}

@media (max-width: 600px) {
  .storage-type-container {
    display: block;
  }

  .header-width {
    width: 76px;
  }

  .table-width {
    word-break: normal !important;
  }

  #react-tiny-popover-container {
    width: max-content;
    max-width: 280px;
    z-index: 21;
  }

  .inventory-icons-block {
    min-width: 144px;
    justify-content: center;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .width-vspGraph-dropdown {
    width: 60% !important;
  }

  .lun-graph-width {
    width: 780px;
  }

  .overflow-lun-graph {
    overflow: auto;
  }

  .hsae-popover-text {
    width: 100%;
  }

  .mobile-css-hnas-graph {
    flex: none;
    flex-wrap: wrap;
    width: 100%;
  }

  .na-status {
    max-width: 1860px;
    width: 85%;
    left: 6px;
    position: absolute;
    z-index: 21;
  }

  .permission-close-icon {
    position: absolute;
    right: 3px;
    top: 6px;
  }

  .hnas-performance-date {
    width: 100%;
  }

  .ms-float-right {
    float: left;
  }

  .MuiAppBar-root.MuiAppBar-root>div>div {
    margin-right: 0px;
    padding-left: 0px;
  }

  .MuiAppBar-root.MuiAppBar-root>div>div:nth-of-type(2)>div>span,
  .MuiAppBar-root.MuiAppBar-root>div>div:nth-of-type(3)>div>span {
    display: none;
  }

  .hide-for-small-device {
    display: none !important;
  }

  .header_subcontent_width-right {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .for-small-device {
    display: flex !important;
  }

  .for-small-device-block {
    display: block !important;
  }

  .display-flex-for-small-device {
    display: flex !important;
  }

  .mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5%;
    width: 100%;
    z-index: 14;
  }

  .topnav-right {
    margin-top: 0;
  }

  .model-image {
    width: 36%
  }

  .subtabs-sec {
    padding: 0px 24px 6px 0px;
  }

  .existing-user-list::-webkit-scrollbar {
    width: 8px;
  }

  .existing-user-list::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background-color: rgb(90, 90, 90, 0.5);
  }

  #MenuSlider {
    position: fixed;
    width: 290px;
    height: 100%;
    background-color: white;
    z-index: 15;
    padding: 25px 15px;
    left: -290px;
    transition: all 500ms linear;
  }

  #MenuSlider.open-slider {
    left: 0px;
    z-index: 1002;
  }

  #MenuSlider ul {
    padding: 0;
  }

  #MenuSlider ul li {
    list-style: none;
    padding: 10px 10px;
    margin-bottom: 20px;
  }

  .menu-slider-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 20px;
    padding-bottom: 12px;
  }

  .menu-slider-logo-wrapper div .aperture-logo {
    height: 15px;
    margin-top: 10px;
    width: 100px;
  }

  .menu-slider-logo-wrapper .nav-brand-name {
    font-size: 18px;
  }

  .active-slider-tab {
    border-left: 5px solid $Red;
    color: $Red;
    font-weight: 600;
  }

  .menu-slider-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100% - 75px);
  }

  .menu-slider-footer-text-1 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  }

  .menu-slider-footer-text-2 {
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
  }

  .permissions-panel-div {
    width: 100%;
    overflow-y: auto;
  }

  #permission_slider_id,
  #add_asset_slider_id,
  #olympus_slider_id,
  #multiShare_permission_slider_id {
    left: -350px;
    width: 100%;
  }

  #permission_slider_id.active,
  #add_asset_slider_id.active,
  #olympus_slider_id.active,
  #multiShare_permission_slider_id.active {
    left: 0px;
  }

  .asset-detail-img-width {
    width: 100px;
    margin-right: 10px;
  }

  .asset-detail-img-width.img-no-access {
    width: 40px;
  }

  .flex-align-center {
    align-items: center
  }

  .display-block-for-small-devices {
    display: block;
  }

  .asset-table-style {
    padding: 0;
  }

  .modal-content {
    width: 90%;
  }

  .mar-pad-for-logo {
    padding-left: 5%;
    margin: 0 5%;
  }

  .hitachi-logo-span {
    padding-right: 8px;
  }

  .eula-content {
    width: 90% !important;
  }

  .eula-text {
    height: calc(100vh - 320px);
  }

  .main-header-for-eula .mobile-nav {
    z-index: 12;
  }

  .other-actions-div {
    justify-content: flex-start;
  }

  .asset-actions-btn {
    justify-content: flex-start !important;
  }

  .inventory-na-status {
    width: calc(100vw - 30px);
  }

  .triangle {
    left: calc(20% - 5px);
  }

  .asset-popup-details {
    left: 0
  }

  .asset-actions-btn-partner .asset-popup-details {
    left: 0;
  }

  .subtabs-main-sec {
    // position: -webkit-sticky;
    top: -17px;
    background-color: #f5f5f5;
    padding-top: 15px;
    padding-left: 15px;
    margin: 0 -15px;
  }

  .subtabs-sec {
    font-size: 16px !important;
    padding-bottom: 10px;
  }

  .asset-info-with-img {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .asset-info-without-img {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .inventory-subheader-right-tableview {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    grid-template-rows: auto auto;
  }


  .inventory-subheader-view-selector {
    grid-row: 1/2;
    grid-column: 2/3;
    align-self: flex-end;
    padding-bottom: 15px;
  }

  .inventory-subheader-actions {
    grid-row: 2/3;
    grid-column: 1/3;
    display: flex;
    justify-content: flex-end;
  }

  .inventory-sort-select {
    width: auto;
    margin-right: 10px;
  }

  .slider-table {
    overflow-x: auto !important;
  }

  .padding-sm-15 {
    padding: 15px !important;
  }

  .model-img-sec {
    grid-row-start: 1;
    grid-row-end: 3;
    align-self: center;
  }

  .location-grid {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .other-actions-main-div {
    flex-direction: column;
    padding: 5px 0px;
  }

  .OtherAction-MainDiv {
    flex-direction: column;
    padding: 5px 0px;
    width: 230px;
  }

  .other-action-icon-sec {
    flex-direction: row;
    padding: 8px 15px;
    margin-left: 0px;
    font-size: 14px;
  }

  .asset-report-icon-pt {
    margin-left: -3px;
  }

  .other-action-icon-sec svg,
  .other-action-icon-sec img {
    width: 27px;
    height: 27px;
    margin-right: 8px;
  }

  .create-volume-card-container {
    grid-gap: 12px;
    display: flex;
    flex-direction: column;
    max-height: 185px;
  }

  .button-text {
    font-size: 10px;
  }
  .predictive-capacity-legends-mobile{
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(312px, 1fr))
  }
}

@media (max-width: 400px) {

  .padding-Left {
    padding-left: 8px !important;
  }
}

@media screen and (max-width: 1423px)and (min-width: 1271px) {
  .evs-nodes {
    flex-wrap: wrap !important;
  }

  .evs-file-sys {
    flex-wrap: nowrap !important;
  }

  .predictive-capacity-legends {
    margin-left: 18px;
    margin-top: 8px;
    display: flex;
    justify-content: left;
    width: 100%;
  }

}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.maintenance-marqee {
  background-color: rgb(251, 242, 216);
  padding: 8px;
  display: flex;
  font-size: 14px;
  line-height: 21px;
}

.maintenace-scheduled-msg {
  margin: auto 0;
  width: 98%;
}

.mr_b_15 {
  margin-bottom: 15px;
}

.requestAccessList>.closeIconPosition {
  position: relative;
  margin-left: 10px;
  top: 3px;
  font-size: 13px;
}

.active-label>.RcpPoButton {
  padding: 2px 8px;
  border: #2064B4 1px solid;
}

.justifyContenteCenter {
  justify-content: center;
}

.requestAccessButton {
  width: 119px;
}

.requestAccessList {
  position: relative;
  background-color: #d7e6cf;
  padding: 5px;
  border-radius: 3px;

}

.marginYaxis15 {
  margin: 15px 0px;
}

.popUpInnerPadding {
  padding: 16px;
}

.headerNotificationIcon {
  // padding: 2px;
  // background-color: #e6f0fd;
  // border-radius: 3px;
  // margin-top: 3px;
  height: 32px;
  width: 32px
}

.headerNotificationIconRedCircle {
  color: #ed4747;
  position: relative;
  left: -11px;
  top: -6px;
  // background-color: #414141;
  // color: white;
  // font-size: 10px !important;
  // // font-weight: bold;
  // width: 16px;
  // height: 16px;
  // border-radius: 16px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // position: relative;
  // top: -5px;
  // left: -14px;
  // line-height: 12px;
}

.disabledRows {
  background-color: #E8E8E8 !important;
  pointer-events: none !important;
  color: #000;
}

// .enabled {
//   color: #2064B4;
//   cursor: pointer;
// }
.notificationGrid {
  display: flex;
  align-items: start;
  gap: 10px;
  padding: 10px;
}

.blackDot {
  color: #414141;
}

.notificationGridGap {
  display: grid;
  gap: 10px;
}

.scrollable-container {
  max-height: calc(100vh - #{$MinusHeight});
  overflow-y: auto;
}

.shareAssetStepperPadding {
  padding: 0px 24px;
}

.fontStepperLabel {
  font-size: 14px;
}

@media screen and (max-width: 369px) {
  .shareAssetStepperPadding {
    padding: 0px 0px;

  }

  .fontStepperLabel {
    font-size: 12px;
  }

}

.enableButton {
  border: 0px;
  color: #146bd2;
  border-radius: 4px;
  padding: 5px;
  background-color: transparent
}

.enableButton:hover {
  background-color: #d5e5fa;
  border: 0px;
  color: #146bd2;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
}

.disableButton {
  border: 0px;
  border-radius: 4px;
  padding: 5px;
  background-color: transparent
}