.display-none{
    display: none;
}
.display-block{
    display: block;
}
.display-inline-block{
    display: inline-block;
}
.center-align {
    display: flex;
    justify-content: center;
  }
.flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-center {
    justify-content: center;
    align-items: center;
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.flex-wrap {
    flex-wrap: wrap;
}

.text_no_wrap {
    text-wrap: nowrap;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.flex-grow {
    flex-grow: 1;
}

.flex-shrink {
    flex-shrink: 1;
}

.flex-none {
    flex: none;
}

.align-items-flex-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-stretch {
    align-items: stretch;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}


.justify-content-around {
    justify-content: space-around;
}

.justify-content-evenly {
    justify-content: space-evenly;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.flex-6 {
    flex: 6;
}

.flex-7 {
    flex: 7;
}

.flex-8 {
    flex: 8;
}

.flex-9 {
    flex: 9;
}

.flex-10 {
    flex: 10;
}
.text-center{
    text-align: center !important;
}
.text-end{
    text-align: end !important;
}
.text-right{
    text-align: right !important;
}
.block{
    display: block !important;
}