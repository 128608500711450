$start: 0;
$end: 100;
$array: "";
@for $i from $start through $end {
  $array: append($array, $i,comma);
  $array: set-nth($array, 1, $start);
}

$spacings: (
     $array    
  ) !default;

  // margin and padding shorthand prefixes
  $prefixes: (
          p  : padding,
          px : (padding-left, padding-right),
          py : (padding-top, padding-bottom),

          pt : padding-top,
          pr : padding-right,
          pb : padding-bottom,
          pl : padding-left,

          m  : margin,
          mx : (margin-left, margin-right),
          my : (margin-top, margin-bottom),

          mt : margin-top,
          mr : margin-right,
          mb : margin-bottom,
          ml : margin-left,
  ) !default;

  // Loop generating all spacing styles
  @each $attr-short, $attr-list in $prefixes {
    @each $space in $spacings {
      .#{$attr-short}-#{ index(($spacings), $space)-1 } {
        @each $attr in $attr-list {
          #{$attr}: #{$space}px !important;
        }
      }
    }
  }

///////////////
// $start: 1;
// $end: 100;
// $array: "";
// @for $i from $start through $end {
//   $array: append($array, $i, comma);
// }

// $spacings: (
//   $array    
// ) !default;

// // margin and padding shorthand prefixes
// $prefixes: (
//   p  : padding,
//   px : (padding-left, padding-right),
//   py : (padding-top, padding-bottom),

//   pt : padding-top,
//   pr : padding-right,
//   pb : padding-bottom,
//   pl : padding-left,

//   m  : margin,
//   mx : (margin-left, margin-right),
//   my : (margin-top, margin-bottom),

//   mt : margin-top,
//   mr : margin-right,
//   mb : margin-bottom,
//   ml : margin-left,
// ) !default;

// // Loop generating all spacing styles
// @each $attr-short, $attr-list in $prefixes {
//   @each $space in $spacings {
//     .#{$attr-short}-#{ index(($spacings), $space)-1 } {
//       @each $attr in $attr-list {
//         #{$attr}: #{$space}px !important;
//       }
//     }
//   }
// }

@media only screen and (min-width: 901px) and (max-width: 1334px) {
  $modified-spacings: ();

  // Modify spacing values
  @each $space in $spacings {
    $modified-space: if($space == 10, $space, $space - 3); // Increase by 2px for all except 10px
    $modified-spacings: append($modified-spacings, $modified-space, comma);
  }

  // Loop generating styles with modified spacing values
  @each $attr-short, $attr-list in $prefixes {
    @each $space in $modified-spacings {
      .#{$attr-short}-#{ index(($modified-spacings), $space)-1 } {
        @each $attr in $attr-list {
          #{$attr}: #{$space}px !important;
        }
      }
    }
  }
}
